<header class="fixed z-10 top-0 left-0 w-full h-18 flex items-center justify-between py-3 pl-6 pr-3 bg-white border-b border-gray-300">
  <p class="py-2.5 font-medium text-lg text-green-600">GMP.online</p>
  <div *ngIf="companyAccount | async as companyAccount" dropdown #dropdown="dropdown" class="relative">
    <button class="flex items-center gap-2 p-3 text-gray-1100 rounded-lg transition hover:bg-gray-200 focus:outline-2 focus:outline-gray-1100 active:bg-gray-300" type="button"><span class="flex-shrink-0"><svg class="w-6 h-6 stroke-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="none" stroke-width="1.5"/><path d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z" fill="none" stroke-width="1.5" stroke-linecap="round"/></svg></span><span class="font-medium text-sm">Konto</span><span class="flex-shrink-0"><svg class="w-4 h-4 stroke-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M4 6L7.5286 9.5286C7.7508 9.7508 7.86193 9.86193 8 9.86193C8.13807 9.86193 8.2492 9.7508 8.4714 9.5286L12 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></span></button>
    <div *ngIf="dropdown.visible" class="absolute z-50 top-full right-0 w-80 min-w-max mt-2 bg-white divide-y divide-gray-300 rounded-2xl shadow-md">
      <div class="p-6 space-y-2">
        <p class="font-medium text-gray-1100">{{ companyAccount.firstName }} {{ companyAccount.lastName }}</p>
        <p class="text-sm text-gray-700">{{ companyAccount.email }}</p>
      </div>
      <ng-template [modalTemplate]="'Zmień hasło'" #passwordModal="modalTemplate">
        <form #form="ngForm" action="" autocomplete="off" (ngSubmit)="changePassword(form, passwordModal)">
          <div class="space-y-2">
            <p class="font-medium text-sm text-gray-1100">Nowe hasło</p>
            <div class="relative">
              <input ngModel #passwordModel="ngModel" class="form-control pr-11" name="newPassword" placeholder="Nowe hasło" [attr.type]="passwordToggle.active ? 'text' : 'password'" required>
              <button #passwordToggle="toggle" toggle class="flex items-center justify-center absolute top-0 right-0 z-10 w-12 h-12 text-gray-700 focus:outline-none" tabindex="-1" aria-label="toggle" type="button">
                <svg *ngIf="!passwordToggle.active" class="w-6 h-6 stroke-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M2.3388 13.0351C2.11827 12.7317 2 12.3703 2 12C2 11.6297 2.11827 11.2683 2.3388 10.9649C3.76884 9.01625 7.28986 5 12 5C16.7101 5 20.2312 9.01625 21.6612 10.9649C21.8817 11.2683 22 11.6297 22 12C22 12.3703 21.8817 12.7317 21.6612 13.0351C20.2312 14.9837 16.7101 19 12 19C7.28986 19 3.76884 14.9837 2.3388 13.0351Z" fill="none" stroke-width="1.5"/><path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="none" stroke-width="1.5"/></svg>
                <svg *ngIf="passwordToggle.active" class="w-6 h-6 stroke-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M2.3388 13.0351C2.11827 12.7317 2 12.3703 2 12C2 11.6297 2.11827 11.2683 2.3388 10.9649C3.76884 9.01625 7.28986 5 12 5C16.7101 5 20.2312 9.01625 21.6612 10.9649C21.8817 11.2683 22 11.6297 22 12C22 12.3703 21.8817 12.7317 21.6612 13.0351C20.2312 14.9837 16.7101 19 12 19C7.28986 19 3.76884 14.9837 2.3388 13.0351Z" fill="none" stroke-width="1.5"/><path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="none" stroke-width="1.5"/><path d="M3 21L21 3" fill="none" stroke-width="1.5"/></svg>
              </button>
            </div>
            <p *ngIf="passwordModel.touched && passwordModel.hasError('required')" class="text-sm text-red-700">To pole jest wymagane</p>
          </div>
          <div class="mt-10">
            <button class="button button-solid-green button-lg w-full" type="submit"><svg *ngIf="formSending" class="w-4 h-4 fill-current animate-spin" xmlns="http://www.w3.org/2000/svg"><path d="M8,16a8,8,0,1,1,8-8A8.009,8.009,0,0,1,8,16ZM8,2a6,6,0,1,0,6,6A6.006,6.006,0,0,0,8,2Z" opacity="0.4"></path><path d="M16,8H14A6.006,6.006,0,0,0,8,2V0A8.009,8.009,0,0,1,16,8Z"></path></svg><span>Ustaw hasło</span></button>
          </div>
        </form>
      </ng-template>
      <ul class="p-2">
        <li><button class="button w-full" type="button" (click)="passwordModal.present()"><span class="flex-shrink-0"><svg class="w-4 h-4 stroke-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M2.84504 12.5631C2.99496 13.6767 3.91726 14.549 5.03961 14.6006C5.98402 14.644 6.94337 14.6667 7.99984 14.6667C9.0563 14.6667 10.0156 14.644 10.96 14.6006C12.0824 14.549 13.0047 13.6767 13.1546 12.5631C13.2525 11.8365 13.3332 11.0917 13.3332 10.3333C13.3332 9.57493 13.2525 8.8302 13.1546 8.10353C13.0047 6.99 12.0824 6.11766 10.96 6.06606C10.0156 6.02265 9.0563 6 7.99984 6C6.94337 6 5.98402 6.02265 5.03961 6.06606C3.91726 6.11766 2.99496 6.99 2.84504 8.10353C2.7472 8.8302 2.6665 9.57493 2.6665 10.3333C2.6665 11.0917 2.7472 11.8365 2.84504 12.5631Z" fill="none" stroke-width="1.5"/><path d="M5 6V4.33333C5 2.67648 6.34315 1.33333 8 1.33333C9.3062 1.33333 10.3333 2.33333 10.6667 3.33333" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.99756 10.3333H8.00356" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></span><span class="flex-1 text-left">Zmień hasło</span></button></li>
        <li><button class="button w-full" type="button" (click)="logout()"><span class="flex-shrink-0"><svg class="w-4 h-4 stroke-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M4.68221 3.66667C3.06551 4.74159 2 6.57969 2 8.66667C2 11.9804 4.68629 14.6667 8 14.6667C11.3137 14.6667 14 11.9804 14 8.66667C14 6.57969 12.9345 4.74159 11.3178 3.66667" fill="none" stroke-width="1.5" stroke-linejoin="round"/><path d="M8 1.33333V6.66667" fill="none" stroke-width="1.5" stroke-linejoin="round"/></svg></span><span class="flex-1 text-left">Wyloguj</span></button></li>
      </ul>
    </div>
  </div>
</header>
<main class="mt-18">
  <router-outlet></router-outlet>
</main>
