// Angular
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, UrlTree } from '@angular/router';
// Models
import { CompanyAccountModel } from '@data/models';
// Services
import { AuthService } from '@data/services/customer/auth.service';
import { CompanyService } from '@data/services/customer/company.service';
// External
import { BehaviorSubject, map, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CustomerGuard {

  public companyAccount: BehaviorSubject<CompanyAccountModel | null>;

  constructor(private router: Router, private authService: AuthService, private companyService: CompanyService) {
    this.companyAccount = new BehaviorSubject<CompanyAccountModel | null>(null);

    const auth = JSON.parse(window.localStorage.getItem('auth') || '""') || null;
    this.authService.auth.next(auth);
  }

  public canActivate(state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.auth.value || !this.authService.auth.value.companyAccountId) {
      return state.url === '/auth/login' || this.router.parseUrl(`/auth/login?redirectUrl=${encodeURIComponent(state.url)}`);
    }

    if (!this.companyAccount.value) {
      return this.companyService.getCompanyAccount(this.authService.auth.value.companyAccountId).pipe(
        map(staffMember => {
          if (staffMember.passwordChangeRequired) {
            return this.router.parseUrl('/auth/first-password');
          }

          this.companyAccount.next(staffMember);
          return true;
        }),
        catchError(error => {
          window.localStorage.removeItem('auth');
          this.authService.auth.next(null);

          return throwError(() => error).pipe(
            map(() => state.url === '/auth/login' || this.router.parseUrl(`/auth/login?redirectUrl=${encodeURIComponent(state.url)}`))
          );
        })
      );
    }

    return true;
  }
}
