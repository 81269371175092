// Angular
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
// Models
import { CompanyAccountModel } from '@data/models';
// Directives
import { ModalTemplateDirective } from '@libs/core/directives/modal-template';
// Guards
import { CustomerGuard } from '@app/customer/guards/customer.guard';
// Services
import { AuthService } from '@data/services/customer/auth.service';
import { CompanyService } from '@data/services/customer/company.service';
import { NotificationService } from '@libs/core/services/notification.service';
// External
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html'
})
export class BaseLayoutComponent {

  public companyAccount: Observable<CompanyAccountModel | null>;
  public formSending: boolean;

  constructor(private router: Router,
              private customerGuard: CustomerGuard,
              private authService: AuthService,
              private companyService: CompanyService,
              private notificationService: NotificationService) {
    this.companyAccount = this.customerGuard.companyAccount;
    this.formSending = false;
  }

  public changePassword(form: NgForm, modal: ModalTemplateDirective): void {
    form.control.markAllAsTouched();

    if (form.invalid || this.formSending) {
      return;
    }

    const { companyAccountId } = this.authService.auth.value!;
    const { newPassword } = form.value;

    this.formSending = true;
    this.companyService.changePassword(companyAccountId, newPassword).pipe(
      finalize(() => {
        this.formSending = false;
      })
    ).subscribe({
      next: () => {
        this.notificationService.showSuccess('Zmiany zapisano pomyślnie.');
        modal.dismiss();
      },
      error: () => {
        this.notificationService.showDanger('Wystąpił błąd podczas zapisywania danych.');
      }
    });
  }

  public async logout(): Promise<void> {
    await this.router.navigate(['auth', 'login']);
    this.customerGuard.companyAccount.next(null);

    window.localStorage.removeItem('auth');
    this.authService.auth.next(null);
  }
}
